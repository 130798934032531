import { default as _91form_93lDmmAgtQEcMeta } from "/codebuild/output/src2801895747/src/form.winx.ai/pages/[[research]]/[form].vue?macro=true";
import { default as deniedP0tqF5XBINMeta } from "/codebuild/output/src2801895747/src/form.winx.ai/pages/[[research]]/denied.vue?macro=true";
import { default as finishkleUDooRkgMeta } from "/codebuild/output/src2801895747/src/form.winx.ai/pages/[[research]]/finish.vue?macro=true";
import { default as interactionUJEuZX0F7sMeta } from "/codebuild/output/src2801895747/src/form.winx.ai/pages/[[research]]/interaction.vue?macro=true";
import { default as startKIXWAuAltQMeta } from "/codebuild/output/src2801895747/src/form.winx.ai/pages/[[research]]/start.vue?macro=true";
import { default as _91_91research_93_93N7pikYD21QMeta } from "/codebuild/output/src2801895747/src/form.winx.ai/pages/[[research]].vue?macro=true";
import { default as indexnmNKiEEQ9yMeta } from "/codebuild/output/src2801895747/src/form.winx.ai/pages/index.vue?macro=true";
import { default as loginScnPzbKDFCMeta } from "/codebuild/output/src2801895747/src/form.winx.ai/pages/login.vue?macro=true";
import { default as redirecthsOzU554TiMeta } from "/codebuild/output/src2801895747/src/form.winx.ai/pages/redirect.vue?macro=true";
export default [
  {
    name: _91_91research_93_93N7pikYD21QMeta?.name ?? "research",
    path: _91_91research_93_93N7pikYD21QMeta?.path ?? "/:research?",
    meta: _91_91research_93_93N7pikYD21QMeta || {},
    alias: _91_91research_93_93N7pikYD21QMeta?.alias || [],
    redirect: _91_91research_93_93N7pikYD21QMeta?.redirect,
    component: () => import("/codebuild/output/src2801895747/src/form.winx.ai/pages/[[research]].vue").then(m => m.default || m),
    children: [
  {
    name: _91form_93lDmmAgtQEcMeta?.name ?? "research-form",
    path: _91form_93lDmmAgtQEcMeta?.path ?? ":form()",
    meta: _91form_93lDmmAgtQEcMeta || {},
    alias: _91form_93lDmmAgtQEcMeta?.alias || [],
    redirect: _91form_93lDmmAgtQEcMeta?.redirect,
    component: () => import("/codebuild/output/src2801895747/src/form.winx.ai/pages/[[research]]/[form].vue").then(m => m.default || m)
  },
  {
    name: deniedP0tqF5XBINMeta?.name ?? "research-denied",
    path: deniedP0tqF5XBINMeta?.path ?? "denied",
    meta: deniedP0tqF5XBINMeta || {},
    alias: deniedP0tqF5XBINMeta?.alias || [],
    redirect: deniedP0tqF5XBINMeta?.redirect,
    component: () => import("/codebuild/output/src2801895747/src/form.winx.ai/pages/[[research]]/denied.vue").then(m => m.default || m)
  },
  {
    name: finishkleUDooRkgMeta?.name ?? "research-finish",
    path: finishkleUDooRkgMeta?.path ?? "finish",
    meta: finishkleUDooRkgMeta || {},
    alias: finishkleUDooRkgMeta?.alias || [],
    redirect: finishkleUDooRkgMeta?.redirect,
    component: () => import("/codebuild/output/src2801895747/src/form.winx.ai/pages/[[research]]/finish.vue").then(m => m.default || m)
  },
  {
    name: interactionUJEuZX0F7sMeta?.name ?? "research-interaction",
    path: interactionUJEuZX0F7sMeta?.path ?? "interaction",
    meta: interactionUJEuZX0F7sMeta || {},
    alias: interactionUJEuZX0F7sMeta?.alias || [],
    redirect: interactionUJEuZX0F7sMeta?.redirect,
    component: () => import("/codebuild/output/src2801895747/src/form.winx.ai/pages/[[research]]/interaction.vue").then(m => m.default || m)
  },
  {
    name: startKIXWAuAltQMeta?.name ?? "research-start",
    path: startKIXWAuAltQMeta?.path ?? "start",
    meta: startKIXWAuAltQMeta || {},
    alias: startKIXWAuAltQMeta?.alias || [],
    redirect: startKIXWAuAltQMeta?.redirect,
    component: () => import("/codebuild/output/src2801895747/src/form.winx.ai/pages/[[research]]/start.vue").then(m => m.default || m)
  }
]
  },
  {
    name: indexnmNKiEEQ9yMeta?.name ?? "index",
    path: indexnmNKiEEQ9yMeta?.path ?? "/",
    meta: indexnmNKiEEQ9yMeta || {},
    alias: indexnmNKiEEQ9yMeta?.alias || [],
    redirect: indexnmNKiEEQ9yMeta?.redirect,
    component: () => import("/codebuild/output/src2801895747/src/form.winx.ai/pages/index.vue").then(m => m.default || m)
  },
  {
    name: loginScnPzbKDFCMeta?.name ?? "login",
    path: loginScnPzbKDFCMeta?.path ?? "/login",
    meta: loginScnPzbKDFCMeta || {},
    alias: loginScnPzbKDFCMeta?.alias || [],
    redirect: loginScnPzbKDFCMeta?.redirect,
    component: () => import("/codebuild/output/src2801895747/src/form.winx.ai/pages/login.vue").then(m => m.default || m)
  },
  {
    name: redirecthsOzU554TiMeta?.name ?? "redirect",
    path: redirecthsOzU554TiMeta?.path ?? "/redirect",
    meta: redirecthsOzU554TiMeta || {},
    alias: redirecthsOzU554TiMeta?.alias || [],
    redirect: redirecthsOzU554TiMeta?.redirect,
    component: () => import("/codebuild/output/src2801895747/src/form.winx.ai/pages/redirect.vue").then(m => m.default || m)
  }
]